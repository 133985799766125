import logo from '../../logo.svg';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios, { Axios, AxiosHeaders } from 'axios';

// Components
import Head from '../../components/head';
import Header from '../../components/header';
//import Footer from '../../components/footer';

import './About.css';

function App() {
  const [setting, setSetting] = useState({
    title: '',
    description: '',
    tags: '',
    image: null,
    instagram: '',
    pinterest: '',
    twitter: '',
    imagePreview: null
  });

  // Fetch Settings
  const FetchSettings = async () => {
    try {
      const response = await axios.get('https://base-api.mahmutguveninsaat.com/settings', {});

      let result = response.data;

      console.log(result);

      if (result['success']) {
        console.log('Geldi!');
        if (result['data']['title'] != null)
          setting.title = result['data']['title'];
        if (result['data']['description'] != null)
          setting.description = result['data']['description'];
        if (result['data']['instagram'] != null)
          setting.instagram = result['data']['instagram'];
        if (result['data']['twitter'] != null)
          setting.twitter = result['data']['twitter'];
        if (result['data']['pinterest'] != null)
          setting.pinterest = result['data']['pinterest'];
        setSetting(setting);
      } else {
        console.log('Gelmedi.');
      }
    } catch (err) {
      console.log('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      console.error('Login error:', err);
    }
  }
  FetchSettings();


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', setting.title);
    formData.append('description', setting.description);
    formData.append('tags', setting.tags);
    if (setting.image) {
      formData.append('image', setting.image);
    }

    try {
      // API çağrısı burada yapılacak
      console.log('Form data:', Object.fromEntries(formData));
      alert('Proje başarıyla güncellendi!');
    } catch (error) {
      console.error('Güncelleme hatası:', error);
      alert('Güncelleme sırasında bir hata oluştu.');
    }
  };

  return (
    <div className="main">
      <Head content={{ page: { title: "Hakkınızda", type: 'contact' } }} />
      <div className="admin-layout">
        <Header content={{ page: { title: "Hakkınızda", type: 'about' } }} />

        <div className="project-edit-form">
          <h2>Hakkınızda Ayarları</h2>
          <form onSubmit={handleSubmit}>
            <input placeholder='başlık' type="text" name="title" value={setting.title} onChange={(e) => { setSetting({ ...setting, title: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <input placeholder='instagram' type="text" name="instagram" value={setting.instagram} onChange={(e) => { setSetting({ ...setting, instagram: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <input placeholder='pinterest' type="text" name="pinterest" value={setting.pinterest} onChange={(e) => { setSetting({ ...setting, pinterest: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <input placeholder='twitter' type="text" name="twitter" value={setting.twitter} onChange={(e) => { setSetting({ ...setting, twitter: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <button type="submit" className="submit-btn">Güncelle</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default App;