import logo from '../../logo.svg';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Navigate } from 'react-router-dom';
import axios, { Axios, AxiosHeaders } from 'axios';

// Components
import Head from '../../components/head';
import Header from '../../components/header';
import Spinner from '../../components/spinner';
//import Footer from '../../components/footer';
var fetchProjects_count = 0;

function App() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editingProject, setEditingProject] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');
  const [type, setType] = useState('');
  const [images, setImages] = useState([]);
  const [youtube, setYoutube] = useState('');
  const [error, setError] = useState('');
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  if (localStorage.getItem('token') == null) {
    return <Navigate to="/auth/login" replace />;
  }
  // Check

  // Fetch Projects
  const FetchProjects = async () => {
    try {
      if (fetchProjects_count > 0)
        setLoading(true);
      console.log("requesttt");

      console.log({
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      const response = await axios.get('https://base-api.mahmutguveninsaat.com/projects', {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      let result = response.data;

      console.log(result);

      if (result['success']) {
        console.log('Geldi!');
        setProjects([...result.data]);
        setLoading(false);
      } else {
        console.log('Gelmedi.');
      }
    } catch (err) {
      console.log('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      console.error('Login error:', err);
      setLoading(false);
    }
    fetchProjects_count++;
  }
  if (fetchProjects_count == 0)
    FetchProjects();

  // Add Project
  const handleAddProject = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('location', location);
    formData.append('date', date);
    formData.append('type', type);
    formData.append('youtube', youtube);
    images.forEach((image, index) => {
      formData.append(`image-${index}`, image.file);
    });

    console.log("formData");

    // Create request
    try {
      const response = await axios.post('https://base-api.mahmutguveninsaat.com/projects', formData, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      });

      let result = response.data;
      console.log(result);

      if (result['success']) {
        console.log('Eklendi!');
        setTitle('');
        setDescription('');
        setImages([]);
        setLocation('');
        setDate('');
        setType('');
        setYoutube(null);
        console.log(result.data);

        setLoading(false);
        FetchProjects();
      } else {
        console.log('Eklenmedi.');
        console.log(result);

      }
    } catch (err) {
      console.log('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      console.error('Login error:', err);
      setLoading(false);
    }
  };

  const handleEditProject = (project) => {
    setEditingProject(project);
  };

  const handleUpdateProject = async () => {
    setLoading(true);
    setProjects(projects.map(p => p.id === editingProject.id ? editingProject : p));

    // Update request
    try {
      const response = await axios.put('https://base-api.mahmutguveninsaat.com/projects/' + editingProject.id, {
        'title': editingProject.title,
        'description': editingProject.description,
        'location': editingProject.location,
        'date': editingProject.date,
        'type': editingProject.type,
        'youtube': editingProject.youtube,
      }, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      });
      if (response.data.success) {
        console.log(response);

        console.log('Güncellendi!');
        setLoading(false);
      } else {
        console.log('Güncellenemedi.');
      }
      setEditingProject(null);
    } catch (err) {
      console.log('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      console.error('Login error:', err);
      setLoading(false);
    }
  };

  const handleDeleteProject = async (id) => {
    // Delete request
    setLoading(true);
    try {
      const response = await axios.delete('https://base-api.mahmutguveninsaat.com/projects/' + id, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.data.success) {
        console.log('Silindi!');
        setProjects(projects.filter(project => project.id !== id));
        setLoading(false);
      } else {
        console.log('Silinemedi.');
      }

    } catch (err) {
      console.log('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      console.error('Login error:', err);
      setLoading(false);
    }
  };

  const handleImagesChange = (e) => {
    const fileList = Array.from(e.target.files);
    const newImages = fileList.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    setImages(prevImages => [...prevImages, ...newImages]);

    setSelectedFiles([...selectedFiles, images]);
  };


  return (
    <div className="main">
      <Head content={{ page: { title: "Projeler", type: 'projects' } }} />
      <div className="admin-layout">
        <Header content={{ page: { title: "Projeler", type: 'projects' } }} />
        {loading ? (<Spinner></Spinner>) : null}

        <main className="main-content">
          <div className="modern-form">
            <form onSubmit={handleAddProject}>
              <h2>Proje Ekle</h2>
              <div className="input-container">
                <input
                  type="file"
                  onChange={handleImagesChange}
                  multiple
                  accept="image/*"
                />
                {selectedFiles.length > 0 && selectedFiles.forEach((f) => (
                  <div>
                    <p>Seçilen dosya: {f.name}</p>
                    <p>Dosya boyutu: {f.size} bytes</p>
                    <p>Dosya tipi: {f.type}</p>
                  </div>
                ))}
              </div>
              <div className="input-container">
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
                <label htmlFor="title">Başlık</label>
              </div>
              <div className="input-container">
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                ></textarea>
                <label htmlFor="description">Açıklama</label>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  id="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  required
                />
                <label htmlFor="location">Lokasyon</label>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  id="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                />
                <label htmlFor="date">Proje Tarihi</label>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  id="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  required
                />
                <label htmlFor="type">Proje Türü</label>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  id="youtube"
                  value={youtube}
                  onChange={(e) => setYoutube(e.target.value)}
                  required
                />
                <label htmlFor="youtube">Youtube Bağlantısı</label>
              </div>
              <button className="submit-btn" type='submit'>Ekle</button>
            </form>
          </div>
          <div className="project-table-container">
            <h2>Projeler</h2>
            <table className="project-table">
              <thead>
                <tr>
                  <th>Kapak</th>
                  <th>Başlık</th>
                  <th>Açıklama</th>
                  <th>Proje Tarihi</th>
                  <th>Proje Türü</th>
                  <th>Lokasyon</th>
                  <th>Video</th>
                  <th>İşlemler</th>
                </tr>
              </thead>
              <tbody>
                {projects.map(project => (
                  <tr key={project.id}>
                    <td><img src={project.cover_image} alt={project.title} className="project-image" /></td>
                    <td>
                      {editingProject && editingProject.id === project.id ? (
                        <input
                          value={editingProject.title}
                          onChange={(e) => setEditingProject({ ...editingProject, title: e.target.value })}
                        />
                      ) : (
                        project.title
                      )}
                    </td>
                    <td>
                      {editingProject && editingProject.id === project.id ? (
                        <input
                          value={editingProject.description}
                          onChange={(e) => setEditingProject({ ...editingProject, description: e.target.value })}
                        />
                      ) : (
                        project.description.length > 25 ? project.description.substr(0, 20) + '...' : project.description
                      )}
                    </td>
                    <td>
                      {editingProject && editingProject.id === project.id ? (
                        <input
                          value={editingProject.date}
                          onChange={(e) => setEditingProject({ ...editingProject, date: e.target.value })}
                        />
                      ) : (
                        project.date
                      )}
                    </td>
                    <td>
                      {editingProject && editingProject.id === project.id ? (
                        <input
                          value={editingProject.type}
                          onChange={(e) => setEditingProject({ ...editingProject, type: e.target.value })}
                        />
                      ) : (
                        project.type
                      )}
                    </td>
                    <td>
                      {editingProject && editingProject.id === project.id ? (
                        <input
                          value={editingProject.location}
                          onChange={(e) => setEditingProject({ ...editingProject, location: e.target.value })}
                        />
                      ) : (
                        project.location
                      )}
                    </td>
                    <td>
                      {editingProject && editingProject.id === project.id ? (
                        <input
                          value={editingProject.youtube}
                          onChange={(e) => setEditingProject({ ...editingProject, youtube: e.target.value })}
                        />
                      ) : (
                        project.youtube.length > 25 ? project.youtube.substr(0, 10) + '...' : project.youtube
                      )}
                    </td>
                    <td>
                      {editingProject && editingProject.id === project.id ? (
                        <button className="btn btn-edit" onClick={handleUpdateProject}>Kaydet</button>
                      ) : (
                        <>
                          <div className='table-buttons'>
                            <a href={project.seo} class="view-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="white">
                                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                              </svg>
                            </a>
                            <button className="btn btn-edit" onClick={() => handleEditProject(project)}>Düzenle</button>
                            <button className="btn btn-delete" onClick={() => handleDeleteProject(project.id)}>Sil</button>
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
}

/*    <button className="btn btn-edit" onClick={() => handleEditProject(project)}>Düzenle</button> */

export default App;