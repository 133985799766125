import logo from '../../logo.svg';
import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import axios from 'axios';

// Components
import Head from '../../components/head';
//import Header from '../../components/header';
//import Footer from '../../components/footer';

function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [isLogin, setIsLogin] = useState(false);

  if (localStorage.getItem('token') != null) {
    return <Navigate to="/settings" replace />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      console.log({
        'username': username,
        'password': password
      });

      const response = await axios.post('https://base-api.mahmutguveninsaat.com/auth/login', {
        username,
        password
      }, {});

      // const response = await axios.get('http://base-api.mahmutguveninsaat.com/auth/login');

      let result = response.data;

      console.log(result);

      if (result['success']) {
        // Giriş başarılı, token'ı localStorage'a kaydedebilirsiniz
        console.log("logged");
        console.log(result);
        
        
        localStorage.setItem('token', result['data']['token']);
        // Kullanıcıyı dashboard sayfasına yönlendirin
        // history.push('/dashboard'); // react-router kullanıyorsanız
        console.log('Giriş başarılı!');
        setIsLogin(true);
      } else {
        setError('Giriş başarısız. Lütfen bilgilerinizi kontrol edin.');
      }
    } catch (err) {
      setError('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      console.log('Login error:', err);
    }
  };

  return (
    <div className="main">
      <Head content={{ page: { type: 'auth' } }} />
      <div className="login-container">
        <h2>Mahmut Güven Tasarım & İnşaat - Web Admin Girişi</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {isLogin == true && <Navigate to="/settings" replace />}
        
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="text"
              id="username"
              placeholder='kullanıcı adı'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              id="password"
              placeholder='şifre'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Giriş Yap</button>
        </form>
      </div>
    </div>
  );
}

export default App;