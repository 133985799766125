import logo from '../../logo.svg';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';

// Components
import Head from '../../components/head';
//import Header from '../../components/header';
//import Footer from '../../components/footer';

function App() {
  return (
    <Navigate to="/auth/login" replace />
  );
}

export default App;