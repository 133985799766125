import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Props from '../models/components/head';

/**
 * 
 * @param {{content: Props}} props 
 * @returns 
 */
function Component(props) {
  let body = props.content.body;
  let params = props.content.params;

  console.log(props);
  //

  //

  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Syne:wght@400..800&display=swap" rel="stylesheet" />
      <meta charset="UTF-8" />
      <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="stylesheet" href={props.content.page != null ?
        props.content.page.type == 'auth' ? '/auth.css' :
          props.content.page.type == 'projects' ? '/projects.css' :
          props.content.page.type == 'contact' ? '/contact.css' :
            '/style.css' : '/style.css'} />
      <link rel="stylesheet" href={'/create.css'} />
      <title>{props.content.page != null ? props.content.page.title : `MAHMUT GÜVEN TASARIM § İNŞAAT`}</title>

    </Helmet>
  );
}

export default Component;