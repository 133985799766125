import logo from '../../logo.svg';
import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import axios from 'axios';

// Components
import Head from '../../components/head';
//import Header from '../../components/header';
//import Footer from '../../components/footer';

function App() {
    localStorage.clear();
    return <Navigate to="/auth/login" replace />;
}

export default App;