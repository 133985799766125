import { BrowserRouter as Router, Routes, Route, useSearchParams, useParams } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Login from '../pages/Auth/Login';
import Logout from '../pages/Auth/Logout';
import Contact from '../pages/Contact/Contact';
import Projects from '../pages/Projects/Projects';
import About from '../pages/About/About';
import Settings from '../pages/Settings/Settings';
import PropsContent from '../models/PropsContent';

/**
* @param {*}  
*/
function R() {
    return (
        <Routes>
            <Route path="/" element={<Home content={{ params: { user_id: "berke" } }} />} />
            <Route path="/auth/login" element={<Login content={{ params: { user_id: "berke" } }} />} />
            <Route path="/contact" element={<Contact content={{ params: { user_id: "berke" } }} />} />
            <Route path="/projects" element={<Projects content={{ params: { user_id: "berke" } }} />} />
            <Route path="/about" element={<About content={{ params: { user_id: "berke" } }} />} />
            <Route path="/settings" element={<Settings content={{ params: { user_id: "berke" } }} />} />
            <Route path="/logout" element={<Logout content={{ params: { user_id: "berke" } }} />} />
        </Routes>
    );
}


export default R;