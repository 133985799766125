import logo from '../../logo.svg';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Navigate } from 'react-router-dom';
import axios, { Axios, AxiosHeaders } from 'axios';

// Components
import Head from '../../components/head';
import Header from '../../components/header';
import Spinner from '../../components/spinner';
//import Footer from '../../components/footer';

import './Settings.css';

function App() {
  const [setting, setSetting] = useState({
    title: '',
    description: '',
    tags: '',
    image: null,
    instagram: '',
    pinterest: '',
    twitter: '',
    phone: '',
    address: '',
    email: '',
    imagePreview: null
  });
  const [loading, setLoading] = useState(true);

  // Fetch Settings
  useEffect(() => {
    const FetchSettings = async () => {
      try {
        const response = await axios.get('https://base-api.mahmutguveninsaat.com/settings', {});

        let result = response.data;

        console.log(result);

        if (result['success']) {
          console.log('Geldi!');
          if (result['data']['title'] != null)
            setting.title = result['data']['title'];
          if (result['data']['tags'] != null)
            setting.tags = result['data']['tags'];
          if (result['data']['description'] != null)
            setting.description = result['data']['description'];
          if (result['data']['instagram'] != null)
            setting.instagram = result['data']['instagram'];
          if (result['data']['twitter'] != null)
            setting.twitter = result['data']['twitter'];
          if (result['data']['pinterest'] != null)
            setting.pinterest = result['data']['pinterest'];
          if (result['data']['phone'] != null)
            setting.phone = result['data']['phone'];
          if (result['data']['address'] != null)
            setting.address = result['data']['address'];
          if (result['data']['email'] != null)
            setting.email = result['data']['email'];
          // setSetting(setting);


          setSetting({ ...setting, setting });
          console.log(setting);
          setLoading(false);
        } else {
          console.log('Gelmedi.');
        }
      } catch (err) {
        console.log('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
        console.error('Login error:', err);
      }
    }
    FetchSettings();
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    const formData = new FormData();
    if (setting.description != null && setting.title != '')
      formData.append('title', setting.title);
    if (setting.description != null && setting.description != '')
      formData.append('description', setting.description);
    if (setting.tags != null && setting.tags != '')
      formData.append('tags', setting.tags);
    if (setting.phone != null && setting.phone != '')
      formData.append('phone', setting.phone);
    if (setting.instagram != null && setting.instagram != '')
      formData.append('instagram', setting.instagram);
    if (setting.twitter != null && setting.twitter != '')
      formData.append('twitter', setting.twitter);
    if (setting.pinterest != null && setting.pinterest != '')
      formData.append('pinterest', setting.pinterest);
    if (setting.address != null && setting.address != '')
      formData.append('address', setting.address);
    if (setting.email != null && setting.email != '')
      formData.append('email', setting.email);
    /*if (setting.image) {
      formData.append('image', setting.image);
    }*/

    try {
      // API çağrısı burada yapılacak
      console.log('Form data:', Object.fromEntries(formData));

      const response = await axios.put('https://base-api.mahmutguveninsaat.com/settings', formData, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      });

      let result = response.data;

      console.log(result);

      if (result['success']) {
        console.log("Updated!");
        alert('Başarıyla güncellendi!');
        setLoading(false);
      } else {
        console.log('Güncellenemedi.');
      }
    } catch (error) {
      console.error('Güncelleme hatası:', error);
      alert('Güncelleme sırasında bir hata oluştu.');
      setLoading(false);
    }
  };

  if (localStorage.getItem('token') == null) {
    return <Navigate to="/auth/login" replace />;
  }

  return (
    <div className="main">
      <Head content={{ page: { title: "Ayarlar", type: 'contact' } }} />
      <div className="admin-layout">
        <Header content={{ page: { title: "Ayarlar", type: 'settings' } }} />
        {loading ? (<Spinner></Spinner>) : null}

        <div className="project-edit-form">
          <h2>Site Ayarları</h2>
          <form onSubmit={handleSubmit}>
            <input placeholder='başlık' type="text" name="title" value={setting.title} onChange={(e) => { setSetting({ ...setting, title: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <input placeholder='açıklama' type="text" name="description" value={setting.description} onChange={(e) => { setSetting({ ...setting, description: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <input placeholder='etiketler' type="text" name="tags" value={setting.tags} onChange={(e) => { setSetting({ ...setting, tags: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <input placeholder='instagram' type="text" name="instagram" value={setting.instagram} onChange={(e) => { setSetting({ ...setting, instagram: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <input placeholder='pinterest' type="text" name="pinterest" value={setting.pinterest} onChange={(e) => { setSetting({ ...setting, pinterest: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <input placeholder='twitter' type="text" name="twitter" value={setting.twitter} onChange={(e) => { setSetting({ ...setting, twitter: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <input placeholder='phone' type="text" name="phone" value={setting.phone} onChange={(e) => { setSetting({ ...setting, phone: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <input placeholder='address' type="text" name="address" value={setting.address} onChange={(e) => { setSetting({ ...setting, address: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <input placeholder='email' type="text" name="email" value={setting.email} onChange={(e) => { setSetting({ ...setting, email: e.target.value }) }} />
            <div style={{ height: 20 }}></div>
            <button type="submit" className="submit-btn">Güncelle</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default App;