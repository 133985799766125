import { BrowserRouter as Router, Routes, Route, useSearchParams, useParams } from 'react-router-dom';
import Login from '../pages/Auth/Login';

/**
* @param {*}  
*/
function R() {
    return (
        <Routes>
            <Route path="/login" element={<Login content={{ params: { user_id: "berke" } }} />} />
        </Routes>
    );
}

export default R;