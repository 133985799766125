import React, { useEffect } from 'react';
import DotLoader from "react-spinners/DotLoader";

function Spinner() {
    //

    /* 
        <li><a href="#dashboard">Dashboard</a></li>
        <li><a href="/contact" className={props.content.page.type == 'contact' ? "active" : ``}>İletişim</a></li>
        <li><a href="/about" className={props.content.page.type == 'about' ? "active" : ``}>Hakkınızda</a></li>
    */
    //

    return (
        <div className='main-spinner'>
            <h3>Yükleniyor...</h3>
            <DotLoader
                color="#00fc12"
                loading={true}
                size={100}
            />
        </div>
    );
}

export default Spinner;