import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Props from '../models/components/head';

/**
 * 
 * @param {{content: Props}} props 
 * @returns 
 */
function Component(props) {
    //

    /* 
        <li><a href="#dashboard">Dashboard</a></li>
        <li><a href="/contact" className={props.content.page.type == 'contact' ? "active" : ``}>İletişim</a></li>
        <li><a href="/about" className={props.content.page.type == 'about' ? "active" : ``}>Hakkınızda</a></li>
    */
    //

    return (
        <nav className="sidebar">
            <div className="sidebar-header">
                <h2>Mahmut Güven Tasarım & İnşaat</h2>
            </div>
            <ul className="sidebar-menu">
                <li><a href="/projects" className={props.content.page.type == 'projects' ? "active" : ``}>Projeler</a></li>

                <li><a href="/settings" className={props.content.page.type == 'settings' ? "active" : ``}>Ayarlar</a></li>
                <li><a href="/logout" className={props.content.page.type == 'logout' ? "active" : ``}>Çıkış</a></li>
            </ul>
        </nav>
    );
}

export default Component;