import { BrowserRouter as Router, Routes, Route, useSearchParams, useParams } from 'react-router-dom';
import './App.css';
import React from 'react';

import Home from './routers/home';
import Auth from './routers/auth';
import User from './routers/user';

// Routers
const Routers = {
  User,
  Auth,
  Home
}

function App() {
  console.log("test");

  return (
    <Router>
      <Routes>
        <Route path="/user/*" element={<Routers.User />} />
        <Route path="/auth/*" element={<Routers.Auth />} />
        <Route path="/*" element={<Routers.Home />} />
      </Routes>
    </Router>
  );
}

export default App;
